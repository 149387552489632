.container-cg {
    max-width: 800px;
    margin: 20px auto;
    padding: 20px;
    border: 1px solid #000;
    border-radius: 20px;
}
.cg-section-title {
    font-size: 20px;
    margin-top: 20px;
}
.cg-section-text {
    font-size: 16px;
    line-height: 1.5;
}
.cg-hr {
    border: 1px solid #000;
    margin: 20px 0;
}

.link-legal {
    color: #474747;
    text-decoration: underline;
    font-weight: bold;
    cursor: pointer;
}