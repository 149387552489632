.mac-imagerow {
    max-width: 20%;
}

.ipad-imagerow {
    max-width: 20%;
}

.gift-component-container {
    display: block;
    text-align: center;
    max-width: 90%;
    margin: 0 auto;
    padding: 20px;
}

.gift-component-title {
    text-align: left;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
}

.gift-component-centered-text {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    font-size: 18px;
    margin-bottom: 20px;
    max-width: 100%;
}

.gift-component-image-row {
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    max-height: 20em;
    max-width: 100%;
    width: 100%;
    aspect-ratio: 1;
}

.gift-component-row-image {
    max-width: 100%;
    margin: 0 10px;
    object-fit: contain;
}


@media screen and (max-width: 980px) {
    .gift-component-container {
        min-height: 117vh;
    }

    .one-image-container {
        min-height: 55vh;
    }

    .one-image-container-android {
        min-height: 0vh;
    }

    .gift-component-title {
        text-align: center;
    }

    .gift-component-centered-text {
        text-align: center;
    }

    .gift-component-image-row {
        display: -webkit-flex;
        /* Safari, iOS, and older versions of Chrome/Android */
        display: -moz-box;
        /* Firefox */
        display: -ms-flexbox;
        /* Internet Explorer 10 */
        display: -webkit-flex;
        /* Chrome and newer versions of Safari/Android */
        display: flex;
        /* Standard syntax */
        -webkit-flex-wrap: wrap;
        flex-wrap: wrap;
        align-items: center;
    }

    .gift-component-row-image {
        max-height: 20em;
        margin: 10px 0;
        display: block;
    }
}