.sidebar {
    position: fixed;
    top: 0;
    left: -250px;
    width: 250px;
    height: 100%;
    background-color: #111;
    transition: left 0.3s ease-in-out;
    z-index: 10;
}

.sidebar.active {
    left: 0;
}

.sidebar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    background-color: #000;
}

.sidebar-header img {
    width: 40px;
    height: 40px;
}

.close-btn {
    background: none;
    border: none;
    color: white;
    font-size: 1.5rem;
    cursor: pointer;
}

.sidebar-links {
    list-style: none;
    padding: 1rem;
}

.sidebar-links li {
    margin-bottom: 1rem;
}

.sidebar-link-wrapper {
    display: flex;
    padding-top: 0.7rem;
}

.no-padding {
    padding-top: 0rem;
}

.sidebar-link-wrapper:hover {
    transform: scale(1.03);
    transition: 200ms;
}

.sidebar-link-wrapper p {
    margin-right: 13px;
}

.sidebar-icon {
    height: 22px;
    width: 22px;
    margin-right: 13px;
}

.sidebar-submenu-icon {
    /* width: 30px;
    height: 30px; */
    margin-top: auto;
    margin-bottom: auto;
}

.sidebar-links p {
    color: white;
    text-decoration: none;
    font-size: 1.2rem;
    display: block;
    transition: color 0.3s;
}


.btn.parallelogram {
    margin: 1rem;
    padding: 0.5rem 1rem;
    background-color: #ffffff;
    color: #333;
    transform: skew(-20deg);
    margin-top: 20px;
    display: inline-block;
}

.skew-fix {
    transform: skew(20deg);
}

.sub-menu {
    list-style: none;
    padding-left: 1.5rem;
    display: none;
}

.sub-menu.active {
    display: block;
    animation: slide-down 0.3s ease-in-out;
    padding-top: 10px;
}

.sub-menu:not(.active) {
    animation: slide-up 0.3s ease-in-out;
}

@keyframes slide-down {
    from {
        transform: translateY(-10px);
        opacity: 0;
    }

    to {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes slide-up {
    from {
        transform: translateY(0);
        opacity: 1;
    }

    to {
        transform: translateY(-10px);
        opacity: 0;
    }
}


.dropdown-toggle {
    background: none;
    border: none;
    color: white;
    font-size: 1.5rem;
    cursor: pointer;
    margin-left: auto;
    margin-right: 1rem;
    transition: transform 0.5s;
}

.chevron-icon.open {
    transform: rotate(180deg);
    transition: 300ms;
}

.chevron-icon.close {
    transform: rotate(0deg);
    transition: 300ms;
}

@media (max-width: 64em) {

    .btn-command-sidebar-container {
        position: absolute;
        left: 0;
        width: 100%;
        bottom: 30px;
        display: flex;
        justify-content: center;
    }

    .btn-command-sidebar {
        width: 63%;
    }
}