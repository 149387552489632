header {
    background-color: black;
    color: white;
    display: flex;
    justify-content: space-between;
}

a {
    color: white;
    text-decoration: none;
    /* cursor: url("../../images/cursor.svg"), pointer; */
}

/* button {
    cursor: url("../../images/cursor-pointer.svg"), pointer; 
} */

.navbar-logo {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 1.5rem;
}

.link {
    margin-top: auto;
    margin-bottom: auto;
}

.link a {
    margin-left: 10px;
    margin-right: 10px;
    cursor: pointer;
}

.btn-contact {
    color: black;
    background-color: white;
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    font-family: 'Open';
    display: inline-block;
    transform: skew(20deg);
    cursor: pointer;
}

.btn {
    background: white;
    display: inline-block;
    color: black;
    font-size: 20px;
    padding: 13px 20px;
    text-decoration: none;
}

.margin {
    margin-top: 20px;
}

.parallelogram {
    transform: skew(-20deg);
}

.btn-black {
    background: black;
    display: inline-block;
    color: white;
    font-size: 20px;
    padding: 10px 20px;
    text-decoration: none;
}

.parallelogram-black {
    transform: skew(-20deg);
}


.skew-fix {
    display: inline-block;
    transform: skew(20deg);
}

.menu-hidden {
    display: none;
}

.menu-icon {
    display: none;
}

.right-navbar {
    display: flex;
}

.navbar-right-icons {
    display: none;
}

.navbar-icon {
    display: block;
    margin-top: auto;
    height: 22px;
    width: 22px;
    /* margin-top: 1.5rem; */
    margin-right: 15px;
}

/* Media query for screens less than 64em (1024px) */
@media (max-width: 64em) {

    .right-navbar {
        display: none;
    }
    

    /* Hide the menu by default */
    .header .link {
        display: none;
    }

    .navbar-icon {
        display: none;
    }

    /* Show the menu when the 'isMenuOpen' state is true */
    .header .link.menu-hidden {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 80px;
        left: 0;
        width: 100%;
        background-color: black;
        z-index: 1000;
    }

    /* Style the links in the menu */
    .header .link.menu-hidden a {
        margin: 10px;
        cursor: pointer;
    }

    .navbar-right-icons {
        display: flex;
    }

    .btn {
        display: none;
    }

    .menu-icon {
        display: block;
        margin-top: auto;
        margin-bottom: auto;
        width: 42px;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .navbar-burger-icon {
        height: 40px;
    }
}
