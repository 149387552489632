@media (min-width: 512px) {
    .top-inputs {
        display: flex;
    }

    .txt-centered {
        margin-right: auto;
        margin-left: auto;
        max-width: 55vw;
        text-align: center;
    }

    .message-placeholder {
        margin-top: 15px;
        border: 1px solid #111;
        font-family: "Sheila";
        font-size: 16px;
        /* padding: 10px; */
        outline: none;
        resize: none;
        font-weight: bold;
        margin-right: auto;
        margin-left: auto;
        margin-left: 0; /* Align left */
        flex: 1; /* Take up remaining space */
    }

    .text-left-align {
        text-align: left;
    }

    .text-field-contact-top {
        margin-top: 10px;
        display: block;
        width: 90%;
        padding: 10px;
        border: 1px solid #111;
        font-family: "Sheila";
        font-size: 16px;
    }

    .container-text-contact {
        margin-right: auto;
        margin-left: auto;
        width: 40%;
        text-align: left;
        margin-top: 25px;
    }

    .text-input-contact-top,
    .message-placeholder {
        width: 100%;
    }

    .text-input-right {
        margin-right: -3.5%;
    }

    .submit-btn {
        left: 45%;
        position: absolute;
    }
}


@media (max-width: 512px) {
    .top-inputs {
        display: block;
    }

    .bottom-text-input-contact {
        margin-top: 10px;
    }


    .txt-centered {
        margin-right: auto;
        margin-left: auto;
        max-width: 90vw;
        text-align: center;
    }

    .message-placeholder {
        margin-top: 15px;
        border: 1px solid #111;
        font-family: "Sheila";
        font-size: 16px;
        padding: 10px;
        outline: none;
        resize: none;
        font-weight: bold;
        margin-right: auto;
        margin-left: auto;
        width: 100%;
    }

    .text-left-align {
        text-align: center;
    }

    .text-field-contact-top {
        width: 80%;
        margin-top: 10px;
        display: block;
        margin-right: auto;
        margin-left: auto;
        padding: 10px;
        border: 1px solid #111;
        font-family: "Sheila";
        font-size: 12px;
    }

    .container-text-contact {
        margin-right: auto;
        margin-left: auto;
        width: 60%;
        text-align: center;
        margin-top: 25px;
    }

}

.div-txt-centered {
    margin-bottom: 120px;
}

.text-input-contact-top {
    width: 100%;
}

.text-input-contact-bottom {
    margin-top: 30px;
}

.other {
    margin-right: auto;
    margin-left: auto;
}