@import './fonts/fonts.css';

html,
body {
    max-width: 100%;
    overflow-x: hidden;
}

body {
    font-family: 'Open';
}

* {
    text-decoration: none;
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}

.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-content {
    margin-top: 7px;
    display: none;
    position: absolute;
    background-color: #181818;
    min-width: 290px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    padding: 16px 20px;
    z-index: 1;
    border-radius: 15px;
}

.dropdown:hover .dropdown-content {
    display: block;
    transition: all 250ms ease-in-out;
}

.dropdown a {
    padding-bottom: 7px;
}

.links-dropdown {
    margin-bottom: 14px;
}

.display-element {
    display: block;
}

.pointer-cursor {
    cursor: pointer;
}
