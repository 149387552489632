.margin-top-button {
    margin-top: 20px;
}

.loading-spinner {
    font-size: 3em;
    margin-bottom: 1em;
}

@media screen and (max-width:920px) {
    .correction-message {
        max-width: 87%;
    }
}