.cart-icon {
    position: relative;
}

.badge {
    margin-left: 10px;
    margin-top: 12px;
    margin-bottom: -5px;
    min-height: 22px;
    min-width: 22px;
}

.cart-icon-navbar {
    display: block;
    margin-top: auto;
    height: 22px;
    width: 22px;
    margin-right: 15px;
}

@media (max-width: 64em) {
    .cart-icon {
        position: relative;
        margin-top: -10px;
    }

    .badge {
        margin-left: 10px;
        margin-top: 0px;
        margin-bottom: -5px;
        min-height: 20px;
        min-width: 20px;
    }

    .cart-icon-navbar {
        display: block;
        margin-top: auto;
        height: 18px;
        width: 18px;
        margin-right: 15px;
    }
}