.caroussel {
    width:100%;
    height: 525px;
}

.tableau{
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    text-align: center;
    margin-top: 5vh;
}
.btn-commande{
    color: white;
    background-color: black;
    border-radius: 20px;
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    font-family: 'Open';
    margin-top: 50px;
    cursor: pointer;
}
.btn-commande:hover{
    background-color: white;
    color: black;
    border: 1px solid black;
    transition: 1s;
    cursor: pointer;
}
.tableau h1{
    font-family: "Open-Bold";
    margin-bottom: 15px;
    font-size: 35px;
}
.tableau-perso{
    display: flex;
    justify-content: space-evenly;
    margin-top: 110px;
    background-color: #111;
    color: white;
    padding-top: 90px;
    padding-bottom: 90px;
}
.container-img-table{
    width: 10%;
}
.img-table{
    max-width: 150%;
    height: auto;
}
.title-home{
    font-size: 35px;
    font-family: "Sheila";
    margin-bottom: 15px;
}
.text-perso{
    margin-left: 30px;
    width: 40%;
    margin-top: 80px;
}
.txt-perso{
    width: 80%;
}
.btn-perso{
    background-color: white;
    color: black;
    border-radius: 20px;
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    font-family: 'Open';
    margin-top: 20px;
}
.btn-perso:hover{
    background-color: transparent;
    color: white;
    border: 1px solid white;
    transition: 1s;
}

/*bombe*/
.bombe-perso{
    display: flex;
    justify-content: center;
    margin-top: 110px;
    color: black;
    padding-top: 90px;
    padding-bottom: 90px;
}
.text-bombe{
    width: 30%;
    margin-top: 30px;
    margin-left: -90px;
}
.txt-bombe{
    width: 80%;
}
.btn-bombe{
    background-color: black;
    color: white;
    border-radius: 20px;
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    font-family: 'Open';
    margin-top: 20px;
}
.btn-bombe:hover{
    background-color: transparent;
    color: black;
    border: 1px solid black;
    transition: 1s;
}
.container-img-bombe{
    width: 30%;
    z-index: -1;
}
#bombe-img{
    margin-left: 175px;
    z-index: -1;
    width: 70%;
}
.deco{
    margin-top: 110px;
    background-color: #111;
    color: white;
    padding-top: -50px;
    padding-bottom: 90px;

}
.container-img-deco{
    display: flex;
    justify-content: center;
    margin-top: 30px;
}
.container-text-deco{
    display: flex;
    justify-content: center;
    margin-top: 30px;
}
.text-deco{
    text-align: center;
    width: 50%;
}
.btn-deco{
    background-color: white;
    color: black;
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    font-family: 'Open';
    margin-top: 20px;
}
.img-deco{
    width: 30%;
    margin-top: -30px;
}
.demo{
    display: flex;
    justify-content: space-evenly;
    margin-top: 90px;
    color: black;
    padding-top: 90px;
    padding-bottom: 90px;

}
.container-text-demo{
    display: flex;
    width: 40%;
    margin-top: 60px;
    justify-content: center;
    margin-top: 30px;
}


/*Button*/
.mouse-cursor-gradient-tracking {
    position: relative;
    margin-top: 20px;
    background: #7983ff;
    padding: 0.5rem 1rem;
    font-size: 1.2rem;
    border: none;
    color: white;
    outline: none;
    overflow: hidden;
    max-width: 12rem;
    border-radius: 15px;
}

.mouse-cursor-gradient-tracking span {
    text-align: center;
    padding-left: 1rem;
}

.button-2 {
    border-radius: 20px;
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    font-family: 'Open';

    color: #fff;
    border: 3px solid #c266d3;
    border-radius:20px;
    background-image: -webkit-linear-gradient(30deg, #c266d3 50%, transparent 50%);
    background-image: linear-gradient(30deg, #c266d3 50%, transparent 50%);
    background-size: 500px;
    background-repeat: no-repeat;
    background-position: 0%;
    -webkit-transition: background 300ms ease-in-out;
    transition: background 300ms ease-in-out;
}
.button-2:hover {
    background-position: 100%;
    background-color: transparent;
    color: #c266d3;
}

.hr-home {
    border: 0;
    height: 1px;
    background-color: #ddd;
    margin: 0;
  }

.m-left-video {
    margin-left: 10%;
    width: 35vw;  
}  

@media screen and (max-width: 920px) {
    .hr-home {
        display: none;
    }

    .caroussel {
        width:100%;
        height: auto;
    }

    .m-left-video {
        margin-left: 0%;
        width: 100%;    
    }
}