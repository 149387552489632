.article-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 2rem;
}

.article-image-desc {
    margin-top: 2rem;
    width: 50%;
    margin-right: auto;
    margin-left: auto;
}

.article-image-desc img {
    max-width: 100%;
    border-radius: 15px;
}

.article-description-desc {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-left: auto;
    margin-right: 12vw;
}

.article-description-desc h1 {
    font-size: 2rem;
    margin-bottom: 2rem;
    margin-right: auto;
    margin-left: 1rem;
}

.article-description-desc p {
    /* font-size: 1rem; */
    margin-bottom: 1rem;
}

.article-description-desc button:hover {
    cursor: pointer;
}

.icon-straight {
    transform: skew(20deg);
}

.unpersonnalized-article-price {
    font-size: 2rem;
    font-family: Sheila;
    margin-left: auto;
    margin-right: 4rem;
    margin-top: 2rem;
}

.alternative-images {
    display: flex;
    /* justify-content: space-between; */
    justify-content: center;
    /* Adjust this based on your preference */
    margin-top: 1rem;
    width: 40rem;
    /* Add margin as per your preference */
}

.alternative-images img {
    width: 5rem;
    /* Adjust the width as per your preference */
    height: 5rem;
    /* Adjust the height as per your preference */
    margin-right: 0.5rem;
    /* Add margin between images as per your preference */
    cursor: pointer;
}

.alternative-images img:last-child {
    margin-right: 0;
    /* Remove margin for the last image */
}

.disabled {
    background-color: grey;
    opacity: 0.5;
    cursor: not-allowed;
}

.cursor-na {
    cursor: not-allowed;
}

.not-available {
    color: red;
    margin-top: 1.5rem;
}

/* Add this CSS to your existing styles */
@media only screen and (max-width: 768px) {
    .article-container {
      flex-direction: column;
      align-items: center;
    }
  
    .article-image-desc {
      width: 100%;
      margin-right: 0;
      margin-left: 0;
    }
  
    .main-image {
      width: 100%;
      height: auto;
      margin-right: 0;
      margin-bottom: 1rem;
    }
  
    .article-description-desc {
      margin-right: 0;
    }

    .alternative-images {
        justify-content: start;
    }
  }
  
  /* Set a fixed height for all images */
  .main-image {
    max-height: 450px; /* Adjust the height as per your preference */
  }
  
  /* Additional styles for mobile responsiveness */
  .article-container {
    padding: 1rem; /* Add padding for better spacing on small screens */
  }
  
  .article-image-desc {
    margin-right: 0;
    margin-left: 0;
  }
  
  .article-description-desc {
    margin-right: 0;
  }
  

  
  /* Add this CSS to your existing styles */
  /* ... (rest of your existing styles) */
  