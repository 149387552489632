.cart-box-incomplete-fields-container {
    display: flex;
    justify-content: center;
    /* Center horizontally */
    align-items: center;
    /* Center vertically */
    height: 100vh;
    /* Use viewport height to cover the entire screen */
    position: fixed;
    /* Fix the container to the viewport */
    top: 0;
    /* Align to the top of the viewport */
    left: 0;
    /* Align to the left of the viewport */
    width: 100%;
    /* Make the container full width */
    background-color: #000000 0.5;
    /* Make the background 50% opaque */
    overflow: auto;
    margin-top: 5vh;
}

.cart-box-incomplete-fields {
    background-color: #ffffff;
    width: 80%;
    /* max-height: 90vh; */
    max-width: 600px;
    text-align: center;
    position: relative;
    /* Use relative positioning for height adjustments */
    border-radius: 10px;
    /* height: 2.5em; */
    /* Box-shadow */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    /* Stack fields vertically */
    align-items: center;
    justify-content: center;
    height: auto;
    /* Let the content determine the height */
    border: #000000 solid 1px;
    overflow-y: auto;
    /* Add this to enable vertical scrolling */
    max-height: 80vh;
}

.cart-box-text {
    /* margin-top: 30vh; */
    margin: 0.5em;
    margin-top: 30vh;
    /* Adjust margin for better spacing */
}

.button-popup-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1.5rem;
}

.button-popup-container a {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
}

.pop-up-not-displayed {
    display: none;
}

.close-popup-x {
    height: 20px;
    width: 20px;
    margin-left: 1rem;
    margin-top: 0.5rem;
    align-self: flex-start;
}

@media screen and (max-width: 900px) {
    .cart-box-text {
        margin-top: 50vh;
    }
}