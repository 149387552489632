.alert-box-incomplete-fields-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
    animation: 0.3s ease-in;
}

.alert-box-incomplete-fields {
    background-color: #ffffff;
    width: 80%;
    max-width: 600px;
    text-align: center;
    position: fixed;
    bottom: 0;
    margin-bottom: 5em;
    border-radius: 10px;
    height: 2.5em;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
}

.alert-box-text {
    margin-left: 0.5em;
}

.popup-icon {
    color: #ca4747;
    height: 2rem;
    width: 2rem;
}

@media screen and (max-width: 980px) {
    .alert-box-incomplete-fields {
        width: 92%;
    }

    .popup-icon {
        margin-left: 5vw;
        margin-right: -4vw;
    }
}