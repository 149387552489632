footer{
    background-color: #111111;
    color: white;
    padding-top: 60px;
    padding-bottom: 60px;
    display: flex;
    justify-content: space-evenly;
    margin-top: 80px;

}
.left-footer{
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
}
.link-footer, .reseaux{
    display: flex;
    flex-direction: column;
}
.link-footer a,.reseaux a{
    margin-top: 10px;
    text-align: center;
}

@media (max-width: 720px) {
    .text-footer{
        margin-left:0px;
        margin-top: 0px;
    }
}

@media (min-width: 720px) {
    .text-footer{
        margin-left:20px;
        margin-top: 20px;
    }
}

.text-footer h1{
    margin-bottom: 10px;
}

.footer-icon {
    height: 20px;
    width: 20px;
    margin-right: 7.5px;
    margin-left: 7.5px;
}

.footer-separator {
    margin-top: 10px;
    height: 0.1px;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    margin-bottom: 5px;
}

.links-reseaux-footer {
    margin-left: auto;
    margin-right: auto;
}

.right-icon-footer {
    margin: 0;
}

@media screen and (max-width:640px) {
    footer {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    footer div {
        margin-top: 30px;
    }
}

.copyright {
    display: block;
}

.reseaux {
    margin-top: auto;
    margin-bottom: auto;
}