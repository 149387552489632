.carousel-provider {

  max-height: 450px;
  width: 100%;
  cursor: auto;
}

.container {
  margin: 0;
  padding: 0;
  display: flex;
  height: 100%;
  max-height: 450px;
  margin-bottom: 5vh;
}

.left-side {
  flex: 1;
  display: flex;
  width: 43%;
  min-width: 43%;
  padding: 20px;
  max-height: 65vh;
}

.left-content {
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  margin-top: 17vh;
  margin-bottom: auto;
  max-width: 80%;
  text-align: center;
}

.right-side {
  flex: 2;
  max-height: 65vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  display: flex;
  align-items: center;
  max-width: 57%;
}

.title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.text {
  font-size: 16px;
  margin-bottom: 20px;
}

.caroussel-text {
  font-size: 15px;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
}

.caroussel-button {
  max-width: 175px;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 1000px) {
  .container {
    flex-direction: column;
  }

  .left-side {
    width: 100%;
  }

  .left-content {
    margin-top: 0;
    margin-bottom: 0;
  }

  /* Force image to display in 16:9 format */
  .right-side {
    max-height: 40vh;
    min-width: 100%;
    max-width: 100%;
    background-size: contain;
  }
}

@media screen and (min-width: 920px) {
  .left-content {
    margin-top: 100px;
  }
}