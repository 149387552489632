.article-item {
  flex: 0 0 calc(33.33% - 20px);
  margin-bottom: 20px;
  margin-right: 20px;
  padding: 10px;
  background-color: #f0f0f0;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.article-item:hover {
  scale: 1.025;
  transition: 0.3s;
}

.article-image {
  text-align: center;
}

.article-image img {
  width: 90%;
  border-radius: 15px;
  margin-bottom: 15px;
  margin-top: 5px;
}

.article-content {
  padding: 10px;
  box-sizing: border-box;
  display: flex;
}

.article-content h3 {
  font-size: 18px;
  margin: 0;
}

.article-content p {
  font-size: 14px;
  margin: 5px 0;
  max-height: 3.6em;
  /* 2 lines of text with line-height 1.8em */
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.8em;
}

.article-content button {
  margin-top: auto;
  padding: 5px 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.article-content button:hover {
  background-color: #0056b3;
}

.btn-unpersonalized-articles {
  text-align: center;
  margin-bottom: 7px;
}

.btn-unpersonalized-articles-container {
  margin-top: 10px;
  text-align: center;
}

.content-left {
  max-width: 70%;
  margin-top: auto;
  margin-bottom: auto;
}

.content-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: auto;
  width: 35%;
}

.cropped-text {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* Number of lines to display */
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.article-price {
  margin-left: auto;
  font-family: "Sheila";
  font-size: 32px;
  font-weight: bold;
  margin-top: 0 ;
}

@media (max-width: 680px) {
  .article-item {
    width: 100%;
    flex-basis: 100%;
    margin-bottom: 25px;
  }
}

.article-item:last-child {
  margin-right: auto;
}



@media (max-width: 960px) {
  .article-item {
    flex: 0 0 calc(50% - 20px);
    margin-right: auto;
  }
}

@media (max-width: 680px) {
  .article-item {
    flex: 0 0 100%;
  }
}


@media (min-width: 960px) {
  .article-item:nth-child(3n - 2) {
    margin-left: auto;
  }

  /* New Line after every 3 articles */
  .article-item:nth-child(3n) {
    margin-right: 0;
  }

  /* center the articles when only two articles in the line */
  .article-item:nth-child(3n - 1) {
    margin-left: auto;
  }

  /* center the articles when only one article in the line */
  .article-item:nth-child(3n - 2) {
    margin-left: auto;
  }

}

/* Solve the gap between the middle article of a full line and a single line */
.article-item:hover {
  transform: scale(1.025);
  transition: 0.3s;
}

/* Stick the button to the bottom of the card without  making the card artificially bigger*/

.article-content {
  display: flex;
  flex-direction: column;
  height: 100%;
}

