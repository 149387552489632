@media (min-width: 728px) {
    .about-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .left {
        justify-content: center;
        align-items: center;
        margin-left: 4em;
        padding: 20px;
        width: 50%;
    }

    .right {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-left: auto;
        margin-right: auto;
        padding: 30px;
    }

    .center-title-about {
        text-align: center;
    }

    .text {
        margin-bottom: 10px;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
    }

    .image {
        max-height: 300px;
        height: auto;
        /* width: auto; */
        margin-bottom: 15px;
    }
}

@media (max-width: 728px) {
    .about-container {
        display: block;
    }

    .left {
        margin-left: auto;
        margin-right: auto;
        padding: 20px;
        width: 80%;
    }

    .right {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-left: auto;
        margin-right: auto;
    }

    .center-title-about {
        text-align: center;
    }

    .text {
        margin-bottom: 10px;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
    }

    .image {
        max-height: 350px;
        height: auto;
        margin-bottom: 15px;
    }
}