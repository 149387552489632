.blog-btn {
    display: flex;
}

.blog-link {
    margin-right: 12px;
}


.blog-spacing {
    position: relative;
    display: block;
    margin-top: 30px;
}

.blog-btn-layout {
    margin-top: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.blog-wrapper {
    min-height: 23vh;
}