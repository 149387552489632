/* Add this CSS to your existing styles */
.cart-page {
    min-height: 100vh;
}

.cart-container {
    flex-direction: column;
    align-items: center;
    padding: 1rem;
}

.cart-articles {
    margin-top: 1rem;
    width: 100%;
}

.cart-article-container {
    display: flex;
    flex-direction: row;
    padding: 1rem;
    border-bottom: 1px solid #ccc;
}

.cart-article-container img {
    max-width: 100%;
    margin-bottom: 1rem;
}

.cart-product-info {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 2rem;
    flex: 1;
}

.cart-product-info h2 {
    margin: 0;
    font-size: 1.2em;
    margin-bottom: 0.5rem;
}

.cart-product-info p {
    margin: 0;
    font-size: 0.9em;
}

.cart-price {
    font-size: 1.2em;
    margin-top: auto;
}

.cart-checkout {
    width: 100%;
    margin-top: 1rem;
    padding: 1rem;
    text-align: center;
}

.summary-cart {
    margin-bottom: 1rem;
}

.summary-cart-field {
    margin-bottom: 1rem;
}

.summary-cart-field p {
    text-align: left;
}

.summary-cart-field span {
    font-size: 1.2em;
    font-weight: bolder;
}

.cart-acceptance {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    margin-right: auto;
    margin-left: auto;
    max-width: 400px;
}

.cart-acceptance input {
    margin-right: 0.2rem;
    margin-bottom: 1.5rem;
}

.cart-empty {
    margin-top: 1rem;
    width: 100%;
    text-align: center;
}

.cart-empty h2 {
    margin-top: 1rem;
    margin-bottom: 1rem;
}


@media only screen and (min-width: 460px) {
    .summary-cart-field p {
        text-align: center;
    }
}