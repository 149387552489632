@media screen and (max-width:1024px) {
    .img-table{
        float: left;
        margin-left: 50px;
        width: 200%;
    }
    .text-perso{
        margin-left: 0px;
        width: 40%;
        margin-top: 0px;
    }
    .text-bombe{
        width: 40%;
        margin-top: 30px;
        margin-left: 140px;
    }
     #bombe-img{
        width: 100%;
        margin-left: -25px;
     }
     .text-footer{
         margin-left: 0px;
     }
     /* .tableau {
        margin-top: 15vh;
     } */
}

@media screen and (max-width:768px) {
    .tableau-perso{
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        margin-left: 0px;
    }
    .text-perso{
        margin-left: 0px;
        text-align: center;
    }
    p.txt-perso{
        margin-left: 20px;
        text-align: center;
        width: 90%;
    }
    .img-table{
        width: 60%;
        margin-left: 0px;
        margin-bottom: 20px;
        display: block;
    }
    .bombe-perso{
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        text-align: center;
        margin-top: 0px;
        padding-bottom: 0px;
    }
    iframe{
        width: 100%;
        height: 100%;
    }
    .img-deco{
        width: 60%;
        margin-left: 0px;
        margin-bottom: 20px;
        display: block;
    }
    #bombe-img {
        width: 60%;
        display: block;
        margin: 0 auto;
    }
    .container-img-bombe{
        width: 100%;
    }
    .container-img-table{
        width: 100%;
        display: flex;
        justify-content: center;
    }
    .text-bombe{
        margin-left: 0px;
    }
    .txt-bombe{
        width: 150%;
        margin-left: -40px !important;
        margin: 0px;
    }
    footer{
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
    footer div{
        margin-top: 30px;
    }
}

/* @media screen and (max-width:768px) {
    .tableau {
        margin-top: 15vh;
    }
} */

@media screen and (max-width:425px) {
    .tableau-perso{
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        margin-left: 0px;
    }
    .text-perso{
        margin-left: 0px;
        text-align: center;
    }
    p.txt-perso{
        margin-left: 0px;
        text-align: center;
        width: 90%;
    }
    p.txt-deco{
        margin-left: -10px;
        text-align: center;
    }
    .img-table{
        width: 60%;
        margin-left: 0px;
        margin-bottom: 20px;
        display: block;
    }
    .bombe-perso{
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        text-align: center;
        margin-top: 0px;
        padding-bottom: 0px;
    }
    iframe{
        width: 100%;
        height: 100%;
    }
    .img-deco{
        width: 60%;
        margin-left: 0px;
        margin-bottom: 20px;
        display: block;
    }
    #bombe-img {
        width: 60%;
        display: block;
        margin: 0 auto;
    }
    .container-img-bombe{
        width: 100%;
    }
    .container-img-table{
        width: 100%;
        display: flex;
        justify-content: center;
    }
    .text-bombe{
        margin-left: 0px;
    }
    .txt-bombe{
        width: 150%;
        margin-left: -40px !important;
        margin: 0px;
    }
    footer{
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
    footer div{
        margin-top: 30px;
    }
    p.title-home {
        margin-left: -20px;
    }
}

@media screen and (max-width:768px) {
    .demo {
        flex-direction: column;
        align-items: center;
    }

    .container-img-demo {
        width: 80%;
        margin: 0 auto;
    }

    .container-text-demo {
        width: 80%;
        margin: 30px auto;
        margin-right: auto;
        margin-left: auto;
        text-align: center;
    }

    iframe {
        width: 100%;
        max-width: 100%;
        margin-bottom: 20px;
    }
}

@media screen and (min-width:769px) {
    .container-img-demo {
        width: 50%;
        margin-right: 20px;
    }

    .container-text-demo {
        width: 40%;
    }

    iframe {
        width: 100%;
        max-width: 100%;
        margin-bottom: 20px;
    }
}