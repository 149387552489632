.title-shop {
    font-size: 40px;
    font-family: "Sheila";
    margin-bottom: 15px;
    text-align: center;
    margin-top: 90px;
}

.buy {
    display: flex;
    justify-content: space-around;
    margin-top: 90px;
    margin-bottom: 70px;

}

.container-img-buy {
    width: 50%;
}

.container-img-buy {
    width: 50%;
}

.container-text-buy {
    width: 50%;
    margin-top: 20px;
}

.img-tableau {
    width: 65%;
    margin-left: 15%;
    position: sticky;
    top: 20px;
}

select {
    background-color: #111111;
    color: white;
    padding: 10px;
    margin-left: 10px;
    border: none;
    font-size: 16px;
    font-family: 'Open';
    /* cursor: url("../../../images/cursor-pointer.svg"), pointer; */
    outline: none;
}

.selector {
    display: flex;
    margin-top: 20px;
}

.selector p {
    margin-top: 10px;
}

.mini-title {
    font-size: 30px;
    font-family: "Sheila";
    margin-bottom: 20px;
}

.orientation {
    margin-top: 30px;
}

.orientation p {
    margin-bottom: 10px;
}

.form-orient {
    display: flex;
}

input[type="radio"].radio {
    display: none;
}

.radio:hover {
    cursor: pointer;
}

input[type="radio"].radio+label {
    padding: 0.3rem 0.5rem;
    margin-right: 20px;
    border: 1px solid #111;
}

input[type="radio"].radio:checked+label {
    background-color: #111111;
    color: white;
}

.other {
    margin-top: 30px;
}

textarea {
    margin-top: 20px;
    border: 1px solid #111;
    font-family: "Sheila";
    font-size: 20px;
    padding: 10px;
    outline: none;
    resize: none;
    font-weight: bold;
}

.exemples {
    margin-top: 20px;

}

.exemples p {
    margin-bottom: 20px;
}

input[type="file"] {
    display: none;
}

.exemples label {
    background-color: #111111;
    color: white;
    padding: 10px 20px;
    margin-top: 20px;
    /* cursor: url("../../../images/cursor-pointer.svg"), pointer; */
}

.montant-final {
    margin-top: 40px;

}

.montant {
    font-size: 30px;
    font-family: "Sheila";
}

.btn-cart {
    margin-top: 20px;
    background-color: white;
    border: 1px solid black;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
    font-family: 'Open';
    outline: none;
}

.btn-cart:hover {
    background-color: black;
    color: white;
}

.radiochecking {
    color: #000;
    /* Black text color */
    background-color: #fff;
    /* White background color */
    text-align: center;
    align-items: center;
}

/* Hide the default radio button */
.radiobutton-bombes {
    display: none;
}

/* Style the custom radio button */
.radio-label {
    position: relative;
    padding-left: 30px;
    /* Adjust as needed to leave space for the custom radio button */
    cursor: pointer;
}

/* Style the custom radio button when unchecked */
.radio-label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 15px;
    /* Adjust as needed for the size of the custom radio button */
    height: 15px;
    /* Adjust as needed for the size of the custom radio button */
    border-radius: 50%;
    border: 1.8px solid black;
    /* Border for unchecked state */
    background-color: white;
    /* Background color for unchecked state */
}

/* Style the custom radio button when checked */
.radio-label:after {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 15px;
    /* Adjust as needed for the size of the custom radio button */
    height: 15px;
    /* Adjust as needed for the size of the custom radio button */
    border-radius: 50%;
    background-color: black;
    /* Change to the desired color for the checked state */
    border: 1.8px solid #636363;
    display: none;
}

/* Show the custom radio button when the actual radio button is checked */
.radiobutton-bombes:checked+.radio-label:after {
    display: block;
}

/* Hide the custom radio button when the actual radio button is checked */
.radiobutton-bombes:checked+.radio-label:before {
    display: none;
}



input[type="radio"] {
    position: absolute;
}

/* 
.radio-label {
    width: 100%;
    height: 100%;
    margin-right: 10px;
    cursor: pointer;
} */

input[type="radio"]:checked {
    color: black;
    /* Selected color 
}

span {
    vertical-align: middle;
    /* Align the text vertically with the radio button */
}

@media screen and (max-width:1024px) {
    .img-tableau {
        margin-left: 10%;
        width: 85%;
    }

    textarea {
        width: 98%;
    }
}

@media screen and (max-width:768px) {
    .img-tableau {
        margin-left: 5%;
        width: 85%;
    }

    textarea {
        width: 98%;
    }
}

@media screen and (max-width:649px) {
    .buy {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
}

@media screen and (max-width:425px) {
    .selector {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .selector p {
        margin-bottom: 10px;
    }

    .form-orient {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    input[type="radio"].radio+label {
        margin-right: 0px;
    }

    textarea {
        font-size: 18px;
        width: 150%;
        margin-left: -50px;
    }
}

.image-preview {
    margin-top: 40px;
}

.preview-image {
    width: 225px;
    height: 225px;
    object-fit: cover;
}