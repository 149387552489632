@font-face {
    font-family: 'Open';
    src: url('./OpenSauceTwo-Regular.ttf');
}
@font-face {
    font-family: 'Open-Bold';
    src: url('./OpenSauceTwo-Bold.ttf');
    font-weight: bold;
}
@font-face {
    font-family: 'Sheila';
    src: url('./Indie.ttf');
    font-weight: bold;
}